.fullbox {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outerbox {
  display: flex;
  width: 100%;
  height: 120px;
  max-width: 450px;
  background: #00b977;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.diebox {
  margin: 0 auto;
  width: 98%;
  height: 97%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgb(0, 58, 0), green, rgb(0, 58, 0));
  border-radius: 10px;
}

.dice-container {
  width: 94%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88%;
  gap: 3%;
}

.dice-wrapper {
  width: 100%;
  height: 100%;
  background: radial-gradient(rgb(114, 114, 114), #151515);
  border-radius: 10px;
}

.dice-image {
  margin: 14px;
  width: 75%; /* Adjust the width as needed */
  height: 77%; /* Adjust the height as needed */
  transition: transform 0.2s ease-in-out; /* Smooth transition when changing the dice face */
}

button {
  margin-top: 30px;
  margin-left: 42%;
}

#leftbox {
  width: 10px;
  height: 50px;
  background: rgb(0, 161, 62);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

#rightbox {
  width: 10px;
  height: 50px;
  background: rgb(0, 161, 62);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
