.transaction-card {
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 16px;
  background-color: #ffffff;
}

.transaction-header {
  padding: 8px 10px;
  /* font-weight: bold; */
  text-align: left;
  color: #ffffff;
}

.transaction-body {
  padding: 10px;
}



.transaction-detail,
.transaction-time,
.transaction-balance {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  background-color: #f2f2f1;
  padding: 2%;
}

.transaction-label {
  color: #80849c;
  font-size: 0.75rem;
  font-weight: bold;
}

.transaction-value {
  color: #80849c;
  text-align: right;
  font-size: 0.75rem;
  font-weight: bold;
}
