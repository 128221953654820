.coinbox-container{
    width:20%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    gap: 2px;
    background: #ffffff;
    border-radius: 10px;
    padding: 0.8em;
    color: rgb(22, 22, 22);
    
}
.coinbox-amount{
    font-size: 1em;
    color:"white"
}
.coinbox-image{
    width: 50px;
    height: 50px;
}

.coinbox-image img{
    width: 100%;
    height: 100%;
}

.coinbox-container.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }