.fullbox {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outerbox {
  display: flex;
  width: 100rem;
  height: 6.5rem;
  max-width: 420px;
  background: #00b977;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;
}

.diebox {
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #005c2e, #008c46, #005c2e);
  border-radius: 7px;
}

.slot-machine-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slot-machine {
  display: flex;
  gap: 5px;
  width: 100%;
  justify-content: space-evenly;
  padding: 0 4px;
}

.reel {
  width: 55px;
  height: 90px;
  background-color: #6a6a6a;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.reel-inner {
  position: absolute;
  top: 0;
  width: 100%;
  margin: 17px 0px;
}

.reel-inner div {
  font-weight: bold;
  color: #768096;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px auto;
}

.numberElem {
  background-color: #e1e1ec;
  border-radius: 50%;
  margin: 0 auto;
  aspect-ratio: 1 / 1;
}

.placeholder-circle {
  background-color: #ffffff; /* Different color for decorative circle */
  border: 2px solid #00b977; /* Optional: a green border or any color */
}

.green-background {
  background-color: #16b15e;
  color: white !important;
}

#leftbox,
#rightbox {
  min-width: 0.5rem;
  height: 2rem;
  background: #008b59;
  border-radius: "20% 0%";
  display: flex;
  justify-content: center;
  align-items: center;
}

.polygon-left {
  position: absolute;
  top: 40%;
  left: 7px;
  width: 6%;
  aspect-ratio: 1/1;
  z-index: 100;
}

.polygon-right {
  position: absolute;
  top: 40%;
  right: 8px;
  width: 6%;
  aspect-ratio: 1/1;
  z-index: 100;
}

/* #leftbox::before, #rightbox::before {
  content: '▶'; 
  font-size: 30px;
  color: white;
} */

#leftbox::before {
  transform: rotate(180deg);
}

@keyframes spin {
  0% {
    top: 0;
  }
  100% {
    top: -2000px;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .reel-inner div {
    font-size: 20px;
  }
}

@media screen and (min-width: 769px) {
  .reel-inner div {
    font-size: 25px;
  }
}
